import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import "bootstrap/dist/css/bootstrap.css";
import { get } from "lodash";
import "./oneTemplate.css"; // Import your own CSS styles and include it in the html className

import logo from './ulogo.png';
import signature from './signature.png';
import marks from './table_for_grade.png';
import bdcertlogo from './bdcerts.png';

const Template = ({ document }) => {
  // Declaring what variables will be available to the template from the certificate
 
  const transcriptData = get(document, "transcript", []);

  // Rendering an array of transcript data
  const transcriptSection = transcriptData.map((t, i) => (
    <tr key={i}>
      <td>{t.courseCode}</td>
      <td>{t.name}</td>
      <td>{t.courseCredit}</td>
      <td>{t.grade}</td>
      {/* <td>{t.score}</td> */}
    </tr>
  ));

  return (
    <div className="container">

<Tabs>
    <TabList>
      <Tab>Certificate</Tab>
      <Tab>Transcript</Tab>
    </TabList>

    <TabPanel>
    <div className="bg-container">

<div className="bodyContainer">
  <div className="university-title">{document.issuers[0].name}</div>
  <div className="university-logo">
      <img src={logo} alt="{document.issuers[0].name}" width="150" />
  </div>

    <div className="gText"> This graduation is presented to </div>
    <div className="sText"> {document.recipient.name} </div>
    <div className="gText"> Who has fulfilled all the requirement of </div>
    <div className="gText"> the program of study the degree of </div>
    <div className="dText">  { document.name } </div>
</div>

<div className="row footerContainer">

  <div className="col-sm small-font">
    <img src={signature} width="50" /><br/>
    ------ <br/>
    President <br/>
    Board of Trustees
  </div>

  <div className="col-sm small-font">
    <img src={signature} width="50" /><br/>
    ------ <br/>
    Vice Chancellor
  </div>

  <div className="col-sm small-font">
    <img src={signature} width="50" /><br/>
    ------ <br/>
    Controller of Examination
  </div>

   
  <div className="clearfix"></div>
</div>

<div className="powered_by">
Digitally Published On - <img src={bdcertlogo} width="100" align="middle" />
</div>

</div>
    </TabPanel>
    <TabPanel>
      <div className="trans-bg">

      <div className="top-one">
          <img src={logo} alt="{document.issuers[0].name}" width="100" align="middle" /> {document.issuers[0].name}
      </div>
      <div className="text-right">{document.recipient.serialNumber}</div>
      <h4 className="border-bottoms caps-on">Academic Transcript</h4>

      <table className="w-100 margin-bottoms" border="0">
        <tr>
          <td className="text-left" width="10%">Degree</td>
          <td className="text-left" width="1%">:</td>
          <td colSpan="4" className="text-left">{document.recipient.course}</td>
        </tr>
        {/* <tr>
          <td className="text-left" width="10%">Session</td>
          <td className="text-left" width="1%">:</td>
          <td className="text-left" width="39%">{document.recipient.session}</td>
          <td className="text-left" width="10%">Student ID</td>
          <td className="text-left" width="1%">:</td>
          <td className="text-left" width="39%">{document.id}</td>
        </tr> */}
        <tr>
          <td className="text-left" width="10%">Date Of Issue</td>
          <td className="text-left" width="1%">:</td>
          <td className="text-left" width="39%">{ moment(document.issuedOn).format('MMMM DD, YYYY')}</td>
          <td className="text-left" width="10%">Name</td>
          <td className="text-left" width="1%">:</td>
          <td className="text-left" width="39%">{document.recipient.name}</td>
        </tr>
      </table>

      {transcriptData !== [] && (
        <div className="row">
         
          <table className="w-100" border="1">
            <tbody>
              <tr>
                <th>Course Code</th>
                <th>Course Title</th>
                <th>Credit Hour(s)</th>
                <th>Letter Grade</th>
                {/* <th>Grade Points</th> */}
              </tr>
              {transcriptSection}
            </tbody>
          </table>
        </div>
      )}


        <table className="w-100 margin-tops">
          <tr>
            <td width="60%">
              &nbsp;
                {/* <table className="w-100" border="0">
                  <tr>
                    <td className="text-left" width="40%">Required Credit Hours</td>
                    <td className="text-left" width="1%">:</td>
                    <td className="text-left">{document.recipient.totalCredit}</td>
                  </tr>

                  <tr>
                    <td className="text-left" width="40%">Transferred Credit Hours</td>
                    <td className="text-left" width="1%">:</td>
                    <td className="text-left">{document.recipient.transferredCredit}</td>
                  </tr>
                  <tr>
                    <td className="text-left" width="40%">Completed Credit Hours</td>
                    <td className="text-left" width="1%">:</td>
                    <td className="text-left">{document.recipient.completeCredit}</td>
                  </tr>
                  <tr>
                    <td className="text-left" width="40%">CGPA</td>
                    <td className="text-left" width="1%">:</td>
                    <td className="text-left">{document.recipient.cgpa}</td>
                  </tr>
                  <tr>
                    <td className="text-left" width="40%">Assessment</td>
                    <td className="text-left" width="1%">:</td>
                    <td className="text-left">{document.recipient.assesment}</td>
                  </tr>
                </table> */}
            </td>
            <td width="40%">
            <img src={marks} width="350" />
            </td>
          </tr>
        </table>
      


      <table className="w-100 margin-tops" border="0">
        <tr>
          <td className="text-left" width="49%">
          <img src={signature} width="50" /><br/>
            ------ <br/>
            Verified By
          </td>
          <td className="text-right" width="49%">

          <img src={signature} width="50" /><br/>
          ------ <br/>
          Controller of Examination

          </td>
        </tr>
      </table>


      </div>
    </TabPanel>
  </Tabs>

     
    </div>
  );
};

export default Template;
