exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Black+Ops+One&display=swap);", ""]);
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./certificate_frame.jpg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./so-white.png"));

// Module
exports.push([module.id, ".bg-container{\n    width: 800px;\n    height: 620px;\n    background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n    text-align: center;\n    font-family: 'Black Ops One', arial;\n    line-height: 28px;\n}\n.bodyContainer{\n    text-align: center;\n    margin: 0 auto;\n    width:80%;\n    padding-top: 60px;\n}\n.university-title{\n    font-weight: bold;\n    font-size: 23px;\n}\n.footerContainer{\n    margin: 20px auto 0 auto;\n    width:80%;\n}\n.signLeft{\n    float: left;\n}\n.dateRight{\n    float: right;\n}\n.sText, .dText{\n    font-style: italic;\n}\n.trans-bg{\n    width: 800px;\n    height: auto;\n    background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n    background-repeat: repeat;\n    background-position: center;\n    text-align: center;\n    font-family: 'Black Ops One', arial;\n    line-height: 28px;\n    padding: 20px;\n}\n\n.small-font{\n    font-size:11px;\n}\n.trans-bg{\n    font-size: 12px;\n}\n.trans-bg table{\n    font-family: Arial, Helvetica, sans-serif;\n}\n.top-one{\n    font-size: 22px;\n}\n.border-bottoms{\n    border-bottom: #000 solid 1px;\n    padding: 5px 0;\n    width:350px;\n    margin: 0px auto;\n} \n.caps-on{\n    text-transform: uppercase;\n}\n\n.margin-bottoms{\n    margin-bottom: 30px;\n}\n.margin-tops{\n    margin-top: 30px;\n}\n\n.powered_by{\n    font-size: 12px;\n    font-weight: normal;\n}", ""]);

